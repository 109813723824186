import {createAction, props} from '@ngrx/store';
import {
  AdressartDTO,
  AdresseDTO,
  FirmaExtendedReadResponseDTO,
  SteuernummerFinanzamtReadDTO
} from '../../openapi/partner-openapi';
import {FinanzamtDTO} from '../../openapi/schluesseldaten-openapi';


export class FirmaEditActions {

  public static updateFirmaUpdateRequestDto = createAction(
    '[Edit] Update the firma-update-request-dto.',
    props<{
      firmaDto: FirmaExtendedReadResponseDTO,
    }>(),
  );

  public static updateFirmenbezeichnung = createAction(
    '[Edit] Update firmenbezeichnung.',
    props<{
      firmenbezeichnung: string,
    }>(),
  );

  public static updateAdressart = createAction(
    '[Edit] Update adressart.',
    props<{
      adressartDTO: AdressartDTO,
    }>(),
  );


  public static updateStrasse = createAction(
    '[Edit] Update strasse.',
    props<{
      strasse: string,
    }>(),
  );

  public static updatePostfach = createAction(
    '[Edit] Update postfach.',
    props<{
      postfach: string,
    }>(),
  );

  public static updatePlz = createAction(
    '[Edit] Update plz.',
    props<{
      plz: string,
    }>(),
  );

  public static updateOrt = createAction(
    '[Edit] Update ort.',
    props<{
      ort: string,
    }>(),
  );

  public static updateLand = createAction(
    '[Edit] Update land.',
    props<{
      land: string,
    }>(),
  );

  public static updateSelectedFinanzamtByFinanzamtsschluessel = createAction(
    '[Edit] Update the selected finanzamt-dto.',
    props<{
      previousFinanzamtsschluessel: number | undefined,
      selectedFinanzamtDto: FinanzamtDTO,
    }>(),
  );

  public static updateSteuernummerByFinanzamtsschluessel = createAction(
    '[Edit] Update steuernummer.',
    props<{
      steuernummer: string,
      finanzamtsschluessel: number,
    }>(),
  );

  public static updateUmsatzsteuerId = createAction(
    '[Edit] Update umsatzsteuer-id.',
    props<{
      umsatzsteuerIdNummer: string,
    }>(),
  );

  public static updateGlaeubigerId = createAction(
    '[Edit] Update glaeubiger-id.',
    props<{
      glaeubigerId: string,
    }>(),
  );

  public static updateDeinBetriebBlockValidity = createAction(
    '[Edit] Update dein-betrieb-block validity.',
    props<{
      valid: boolean,
    }>(),
  );

  public static updateBetriebsadresseBlockValidity = createAction(
    '[Edit] Update betriebsadresse-block validity.',
    props<{
      valid: boolean,
    }>(),
  );

  public static updateBetriebsinformationenBlockValidity = createAction(
    '[Edit] Update betriebsinformationen-block validity.',
    props<{
      valid: boolean,
    }>(),
  );

  public static updateSelectedBankkonto = createAction(
    '[Edit] Update selected bankkonto.',
    props<{
      bankkontoId: string,
    }>(),
  );

/*   public static updateKontakt = createAction(
    '[Edit] Update kontakt-dto.',
    props<{
      kontakt: Kontakt-Dto,
    }>(),
  );
 */
  public static updateKontaktBlockValidity = createAction(
    '[Edit] Update kontakt-block validity.',
    props<{
      valid: boolean,
    }>(),
  );

  public static updateBankkontoBlockValidity = createAction(
    '[Edit] Update bankkonto-block validity.',
    props<{
      valid: boolean,
    }>(),
  );
}
