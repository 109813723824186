import {FirmaEditState} from '../states/firma-edit.state';
import {createReducer, on} from '@ngrx/store';
import {FirmaEditActions} from '../actions/firma-edit.actions';
import {
  AdressartDTO,
  AdresseDTO, FirmaUpdateRequestDTO,
  KommunikationDTO, SteuernummerFinanzamtReadDTO,
  SteuernummerFinanzamtUpdateDTO
} from '../../openapi/partner-openapi';


export const initialFirmaEditState: FirmaEditState = {
  formBlockDeinBetriebValid: false,
  formBetriebsadresseValid: false,
  formBetriebsinformationenValid: false,
  formBlockBankkontoValid: false,
  formBlockKontaktValid: false,
};

export const firmaEditReducer = createReducer(
  initialFirmaEditState,

  on(FirmaEditActions.updateFirmaUpdateRequestDto, (state, {firmaDto}) => {

    /*
     * INFO:
     * Erstellt ein FirmaUpdateRequestDTO aus den Daten zu aktualisierenden Firma.
     * Wichtig ist hierbei, dass nicht nur die Daten aus dem Formular in das FirmenUpdateRequestDTO übertragen werden,
     * sondern auch die Daten, die bereits im FirmaDTO vorhanden sind und entsprechend erhalten bleiben sollen.
     * Alle Informationen die bei dem Update nicht gesetzt sind, werden gelöscht, da es sich um ein HTTP PUT handelt.
     */

    const previousAdresseDto: AdresseDTO | undefined = firmaDto.adresse;
    const updatedAdresseDto: AdresseDTO = {
      adressart: previousAdresseDto.adressart,
      strasse: previousAdresseDto?.strasse ?? undefined,
      postfach: previousAdresseDto?.postfach ?? undefined,
      ort: previousAdresseDto?.ort || '',
      land: previousAdresseDto.land ?? undefined,
      postleitzahl: previousAdresseDto?.postleitzahl || '',
    };

    const previousKommunikationenDtos: KommunikationDTO[] | undefined = firmaDto.kommunikationen;
    const updatedKommunikationenDtos: KommunikationDTO[] | undefined = previousKommunikationenDtos?.map(previousKommunikationDto => {
        const updatedKommunikation: KommunikationDTO = {
          exportEtax: previousKommunikationDto.exportEtax,
          geschaeftsfuehrung: previousKommunikationDto.geschaeftsfuehrung,
          inhalt: previousKommunikationDto.inhalt,
          kommunikationId: previousKommunikationDto.kommunikationId,
          medium: previousKommunikationDto.medium,
          nutzung: previousKommunikationDto.nutzung,
          standard: previousKommunikationDto.standard,
        };

        return updatedKommunikation;
      }
    );

    const previousSteuernummernDtos: SteuernummerFinanzamtReadDTO[] | undefined = firmaDto.steuernummern;
    const updatedSteuernummernDtos: SteuernummerFinanzamtUpdateDTO[] | undefined = previousSteuernummernDtos?.map(previousSteuernummer => {
      const updatedFinanzamtDto: SteuernummerFinanzamtUpdateDTO = {
        exportEtax: previousSteuernummer.exportEtax,
        finanzamtsartAnsaessigkeitAusland: previousSteuernummer.finanzamtsartAnsaessigkeitAusland,
        finanzamtsartBetrieb: previousSteuernummer.finanzamtsartBetrieb,
        finanzamtsartLohnsteuer: previousSteuernummer.finanzamtsartLohnsteuer,
        finanzamtsartUmsatzsteuer: previousSteuernummer.finanzamtsartUmsatzsteuer,
        finanzamtsartWohnsitz: previousSteuernummer.finanzamtsartWohnsitz,
        finanzamtsschluessel: previousSteuernummer.finanzamtsschluessel,
        sepaLastschrift: previousSteuernummer.sepaLastschrift,
        steuernummer: previousSteuernummer.steuernummer,
      };

      return updatedFinanzamtDto;
    });

    const requestDto: FirmaUpdateRequestDTO = {
      firmenbezeichnung: firmaDto.firmenbezeichnung,
      firmennummer: firmaDto.firmennummer,
      adresse: updatedAdresseDto,
      kommunikationen: updatedKommunikationenDtos,
      glaeubigerId: firmaDto.glaeubigerId ?? undefined,
      steuernummern: updatedSteuernummernDtos,
      umsatzsteuerIdNummer: firmaDto.umsatzsteuerIdNummer ?? undefined,
    };

    return {
      ...initialFirmaEditState,
      requestDto,
      originalSteuernummerFinanzamtUpdateDtos: updatedSteuernummernDtos,
    };
  }),

  on(FirmaEditActions.updateFirmenbezeichnung, (state, {firmenbezeichnung}) => {
    return {
      ...state,
      requestDto: {
        ...state.requestDto!,
        firmenbezeichnung,
      },
    };
  }),

  on(FirmaEditActions.updateAdressart, (state, {adressartDTO}) => {
    return {
      ...state,
      requestDto: {
        ...state.requestDto!,
        adresse: {
          ...state.requestDto!.adresse,
          adressart: adressartDTO,
          strasse: undefined,
          postfach: undefined,
        },
      },
    };
  }),

  on(FirmaEditActions.updateStrasse, (state, {strasse}) => {
    return {
      ...state,
      requestDto: {
        ...state.requestDto!,
        adresse: {
          ...state.requestDto!.adresse,
          strasse: strasse ?? undefined,
        },
      },
    };
  }),

  on(FirmaEditActions.updatePostfach, (state, {postfach}) => {
    return {
      ...state,
      requestDto: {
        ...state.requestDto!,
        adresse: {
          ...state.requestDto!.adresse,
          postfach: postfach ?? undefined,
        },
      },
    };
  }),

  on(FirmaEditActions.updatePlz, (state, {plz}) => {
    return {
      ...state,
      requestDto: {
        ...state.requestDto!,
        adresse: {
          ...state.requestDto!.adresse,
          postleitzahl: plz,
        },
      },
    };
  }),

  on(FirmaEditActions.updateOrt, (state, {ort}) => {
    return {
      ...state,
      requestDto: {
        ...state.requestDto!,
        adresse: {
          ...state.requestDto!.adresse,
          ort,
        },
      },
    };
  }),

  on(FirmaEditActions.updateLand, (state, {land}) => {
    return {
      ...state,
      requestDto: {
        ...state.requestDto!,
        adresse: {
          ...state.requestDto!.adresse,
          land,
        },
      },
    };
  }),

  // INFO: Aktualisieren des ausgewählten Finanzamts anhand des Finanzamtsschlüssels.
  on(FirmaEditActions.updateSelectedFinanzamtByFinanzamtsschluessel, (state, {
    previousFinanzamtsschluessel,
    selectedFinanzamtDto
  }) => {

    /*
     * INFO:
     * Sollte bereits ein Finanzamt ausgewählt sein, wird dieses, anhand des Finanzamtsschlüssels,
     * durch das neu ausgewählte Finanzamt ersetzt.
     *
     * Existierte bislang kein Finanzamt, wird ein neues Finanzamt hinzugefügt.
     */
    let updatedSteuernummernDtos: SteuernummerFinanzamtUpdateDTO[] = [...state.requestDto!.steuernummern || []];

    if (previousFinanzamtsschluessel) {
      updatedSteuernummernDtos = updatedSteuernummernDtos.map(steuernummerFinanzamtDto => {

        if (steuernummerFinanzamtDto.finanzamtsschluessel == previousFinanzamtsschluessel) {

          return {
            ...steuernummerFinanzamtDto,
            finanzamtsartBetrieb: true,
            finanzamtsschluessel: selectedFinanzamtDto.finanzamtsschluessel!,
          };
        }

        return steuernummerFinanzamtDto;
      })!;
    } else {

      const newSteuernummerFinanzamtUpdateDTO: SteuernummerFinanzamtUpdateDTO = {
        finanzamtsschluessel: selectedFinanzamtDto.finanzamtsschluessel!,
        steuernummer: '',
        finanzamtsartBetrieb: true,
        finanzamtsartAnsaessigkeitAusland: false,
        finanzamtsartLohnsteuer: false,
        finanzamtsartUmsatzsteuer: false,
        finanzamtsartWohnsitz: false,
        sepaLastschrift: false,
        exportEtax: false,
      };

      updatedSteuernummernDtos.push(newSteuernummerFinanzamtUpdateDTO);
    }

    return {
      ...state,
      selectedFinanzamtDto,
      requestDto: {
        ...state.requestDto!,
        steuernummern: updatedSteuernummernDtos,
      },
    };
  }),

  on(FirmaEditActions.updateSteuernummerByFinanzamtsschluessel, (state, {finanzamtsschluessel, steuernummer}) => {
    const updatedSteuernummernDtos: SteuernummerFinanzamtUpdateDTO[] = [...state.requestDto!.steuernummern!].map(steuernummerFinanzamtDto => {

      /*
       * INFO:
       * Das SteuernummerFinanzamtUpdateDTO mit dem passenden Finanzamtsschlüssel wird aktualisiert
       * und als Finanzamtsart 'Betrieb' markiert.
       */
      if (steuernummerFinanzamtDto.finanzamtsschluessel == finanzamtsschluessel) {

        return {
          ...steuernummerFinanzamtDto,
          finanzamtsartBetrieb: true,
          steuernummer,
        };
      }

      return steuernummerFinanzamtDto;
    })!;

    return {
      ...state,
      requestDto: {
        ...state.requestDto!,
        steuernummern: updatedSteuernummernDtos,
      },
    };
  }),

  on(FirmaEditActions.updateUmsatzsteuerId, (state, {umsatzsteuerIdNummer}) => {
    return {
      ...state,
      requestDto: {
        ...state.requestDto!,
        umsatzsteuerIdNummer: umsatzsteuerIdNummer ?? undefined,
      },
    };
  }),

  on(FirmaEditActions.updateGlaeubigerId, (state, {glaeubigerId}) => {
    return {
      ...state,
      requestDto: {
        ...state.requestDto!,
        glaeubigerId: glaeubigerId ?? undefined,
      },
    };
  }),

  on(FirmaEditActions.updateDeinBetriebBlockValidity, (state, {valid}) => {
    return {
      ...state,
      formBlockDeinBetriebValid: valid,
    };
  }),

  on(FirmaEditActions.updateBetriebsadresseBlockValidity, (state, {valid}) => {
    return {
      ...state,
      formBetriebsadresseValid: valid,
    };
  }),

  on(FirmaEditActions.updateBetriebsinformationenBlockValidity, (state, {valid}) => {
    return {
      ...state,
      formBetriebsinformationenValid: valid,
    };
  }),

  on(FirmaEditActions.updateSelectedBankkonto, (state, {bankkontoId}) => {
    return {
      ...state,
      selectedBankkonto: bankkontoId ? bankkontoId : undefined,
    };
  }),

  on(FirmaEditActions.updateKontaktBlockValidity, (state, {valid}) => {
    return {
      ...state,
      formBlockKontaktValid: valid,
    };
  }),

  on(FirmaEditActions.updateBankkontoBlockValidity, (state, {valid}) => {
    return {
      ...state,
      formBlockBankkontoValid: valid,
    };
  }),
);
