import {createAction, props} from '@ngrx/store';


export class AppContextActions {

  public static setPopupMode = createAction(
    '[Intent Action Service] Change popup mode',
    props<{
      popupMode: boolean,
    }>(),
  );

}
