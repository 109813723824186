import {AppContextState} from '../states/app-context.state';
import {createReducer, on} from '@ngrx/store';
import {AppContextActions} from '../actions/app-context.actions';


export const initialAppContextState: AppContextState = {
  popupMode: false,
};

export const appContextReducer = createReducer(
  initialAppContextState,

  on(AppContextActions.setPopupMode, (state, {popupMode}) => {
    return {
      ...state,
      popupMode,
    };
  }),
);
